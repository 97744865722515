import React from "react";
import Icon from "../UI/Icon";

const LockIcon = () => {
  return (
    <Icon viewBox="0 0 30 31">
      <path d="M22.5 10.5H21.25V8C21.25 4.55 18.45 1.75 15 1.75C11.55 1.75 8.75 4.55 8.75 8V10.5H7.5C6.125 10.5 5 11.625 5 13V25.5C5 26.875 6.125 28 7.5 28H22.5C23.875 28 25 26.875 25 25.5V13C25 11.625 23.875 10.5 22.5 10.5ZM11.25 8C11.25 5.925 12.925 4.25 15 4.25C17.075 4.25 18.75 5.925 18.75 8V10.5H11.25V8ZM21.25 25.5H8.75C8.0625 25.5 7.5 24.9375 7.5 24.25V14.25C7.5 13.5625 8.0625 13 8.75 13H21.25C21.9375 13 22.5 13.5625 22.5 14.25V24.25C22.5 24.9375 21.9375 25.5 21.25 25.5ZM15 21.75C16.375 21.75 17.5 20.625 17.5 19.25C17.5 17.875 16.375 16.75 15 16.75C13.625 16.75 12.5 17.875 12.5 19.25C12.5 20.625 13.625 21.75 15 21.75Z" />
    </Icon>
  );
};

export default LockIcon;
