import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Site from "../components/Layout/Site";
import { Card, CardContent } from "../components/UI/Card";
import Element from "../components/UI/Element";
import {
  FieldGroup,
  Field,
  FieldContainer,
  FieldError,
} from "../components/UI/Forms";
import { Row, Col, Container } from "../components/UI/Grid";
import { Heading } from "../components/UI/Typography";
import styled from "styled-components";
import Button from "../components/UI/Button";
import LockIcon from "../components/Icons/LockIcon";
import ScaledImage from "../components/UI/ScaledImage";
import { StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";
import { isLoggedIn, handleLogin } from "../services/auth";
import { media } from "styled-bootstrap-grid";
import Seo from "../components/Layout/Seo";

const schema = yup.object().shape({
  password: yup.string().required("This is required."),
});

const Login = ({ location }) => {
  if (typeof window !== `undefined` && isLoggedIn()) {
    navigate("/annual-reports");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState("");

  const onSubmit = (data) => {
    handleLogin(data);

    if (isLoggedIn()) {
      navigate(`/annual-reports`);
    } else {
      setError("Invalid password.");
    }
  };

  return (
    <Site page="login">
      <Seo title="Login" />
      <LoginContainer>
        <BgContainer>
          <ScaledImage bg="true">
            <StaticImage src="../images/bg-login.jpg" alt="bg" />
          </ScaledImage>
        </BgContainer>
        <Container>
          <Row>
            <Col col={{ lg: 4 }}>
              <Card>
                <CardContent>
                  <Heading size={"h3"} textAlign="center" mb={4}>
                    Annual Report Login
                  </Heading>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Element display="flex" flexDirection="column" rowGap={8}>
                      <FieldGroup>
                        <FieldContainer prependIcon>
                          <Field
                            type="password"
                            placeholder="Your Password"
                            prepend="true"
                            {...register("password")}
                          />
                          <LockIcon />
                        </FieldContainer>
                        {errors.password && (
                          <FieldError>{errors.password.message}</FieldError>
                        )}
                        {error && <FieldError>{error}</FieldError>}
                      </FieldGroup>
                      <FieldGroup>
                        <Button variant="brand" block="true" type="submit">
                          Login
                        </Button>
                      </FieldGroup>
                    </Element>
                  </form>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoginContainer>
    </Site>
  );
};

const LoginContainer = styled.div`
  min-height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8eaf6;

  ${media.lg`
    min-height: calc(100vh - 80px);
  `}
`;

const BgContainer = styled.div`
  height: 100%;
  background: red;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  ${media.lg`
    width: 50%;
  `}
`;
export default Login;
